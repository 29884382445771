import React from 'react';
import './DeviceList.scss';

interface DeviceListProps {
  children: React.ReactNode;
}

export default function DeviceList({ children }: DeviceListProps) {
  return (
    <ul className='company-list' aria-label='company list'>
      {children}
    </ul>
  );
}
