import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import DeviceList from 'components/DeviceList/DeviceList';
import CircularProgress from '@mui/material/CircularProgress';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentBurnInFilters, } from 'pages/Agent/navigation';
import { burnInSortOptions, } from 'pages/Agent/menuLinks';
import { useAuth } from 'context/useAuth';
import { useAppSettings } from 'context/appsettingsContext';
import axios from 'axios';
import DeviceCard from 'components/DeviceCard/DeviceCard';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import CustomOption from 'components/CustomOption/CustomOption';
import Fab from '@mui/material/Fab';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import './AllDevices.scss';

export interface Device {
  id: number;
  active: boolean;
  serialNumber: string;
  testStation?: string;
}

export default function AllDevices() {
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<Device[]>([]);
  const [allDevices, setAllDevices] = useState<Device[]>([]);
  const [currentDevice, setCurrentDevice] = useState<Device>();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const [lastUpdated, setLastUpdated] = useState("");
  const [deviceTestResults, setDeviceTestResults] = useState<Record<string, any>>({});
  const deviceModalRef = useRef();
  const [selectedStation, setSelectedStation] = useState<string>();
  const [manualStationIds, setManualStationIds] = useState<string[]>([]);
  const [stationIds, setStationIds] = useState<string[]>([]);
  const stationIdInputRef = useRef<HTMLInputElement>(null);
  const [autoRefresh, setAutoRefresh] = useState(false);

  useEffect(() => {
    getDevices("");
    getResults();
  }, []);

  async function getDevices(searchText: string) {
    try {
      setLoading(true);
      const URL = `${settings.baseURL}/Devices?$filter=contains(SerialNumber, '${searchText}')&$pagesize=50&$orderby=SerialNumber asc`;
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

       // Set device to all devices that are not already in the devices list
      const allDBDevices: Device[] = response.data.items;
      const existingSerialNumbers = new Set(devices.map(device => device.serialNumber));
      const filteredDevices = allDBDevices.filter((x: Device) => !existingSerialNumbers.has(x.serialNumber));

      // Remove all station ids from stationIds array that are already in use by devices
      const stationIdsToIgnore = new Set(allDBDevices.map((device: Device) => device.testStation));

      setStationIds(stationIds.filter(stationId => !stationIdsToIgnore.has(stationId)));
      setAllDevices(filteredDevices);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    } finally {
      setLoading(false);
    }
  }

  function handleDeviceSelect(device: Device) {
    setSelectedDevice(prevState => ({
      ...prevState,
      ...device
    }));
  }

  function clearSelectedDevice() {
    setSelectedDevice(undefined);
    setSelectedStation(undefined);
  }

  function handleDeviceSearch(event: ChangeEvent<HTMLInputElement>) {
    var { value } = event.target;
    setSelectedDevice(undefined);
    if (value.length >= 3) {
      getDevices(value);
    } else {
      // setDevices([]); // clear
    }
  }

  async function addDeviceToList(device: Device) {
    let newDevices = devices;
    if (!newDevices.find(x => x.id === device.id)) {
      await addUnit(selectedDevice!.serialNumber);
      getResults();
      // newDevices.push(device);
      // setDevices(newDevices);
    }
  }

  function changeLinkedDevice() {
    const selectedDeviceId = selectedDevice?.id;
    
    // Add device
    addDeviceToList(selectedDevice!);

    // Clear selected device
    setSelectedDevice(undefined);

    // Clear current device
    setCurrentDevice(undefined);

    // Remove selected device from all devices
    setAllDevices(allDevices.filter(x => x.id !== selectedDeviceId && selectedDeviceId !== undefined));

    // Close modal
    (deviceModalRef.current as any)?.close();
  }

  function abortDeviceSearch() {
    setSelectedDevice(currentDevice);
    setSelectedStation(undefined);
    setManualStationIds([]);
  }

  function removeDevice() {
    if (selectedDevice && devices.find(x => x.id === selectedDevice.id)) {
      removeUnit(selectedDevice!.serialNumber);
      setDevices(devices.filter(x => x.id !== selectedDevice.id));

      // Add selected device's station back to station list
      setStationIds([...stationIds, selectedDevice.testStation || 'N/A']);

      // Add selected device to all devices
      setAllDevices([...allDevices, selectedDevice]);
    }
  }

  function handleStationSelect(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedStation(event.target.value);
  }
  
  function handleStationInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target.value;

    if (input === '') {
      setManualStationIds([]);
      return;
    }

    // Remove any empty entries
    const stationIdsArray = input.split(',').map(id => id.trim()).filter(id => id !== '');
    // Set state
    
    setManualStationIds(stationIdsArray);
  }

  function clearStationInput() {
    setManualStationIds([]);

    if (stationIdInputRef.current) {
      stationIdInputRef.current.value = '';
    }
  }

  function changeStationInput() {
    setStationIds(Array.from(new Set([...stationIds, ...manualStationIds])));
    setManualStationIds([]);
  }

  function renderAddDeviceModalContent() {
    if (stationIds.length === 0) {
      return (
        <>
          <p>
            No stations were retrieved, please contact the Software Team or enter the station IDs manually below:
          </p>
          <p style = {{
            fontSize: 12,
            color: "grey",
            textDecoration: "italic",
          }}>
            Enter comma-separated list. Example: burn1, burn2, burn3, etc
          </p>
          <div className='form-field' style = {{
            marginTop: 10,
          }}>
            {
              (manualStationIds == null || manualStationIds.length === 0) && 
              <label htmlFor='stationIdInput'>burn1, burn2, burn3, etc</label>
            }
            <input
              ref={stationIdInputRef}
              id='stationIdInput'
              autoComplete='off'
              placeholder='Enter station IDs'
              onChange={handleStationInputChange}
              className='vehicle-card__stationIdInput'
              defaultValue={manualStationIds.join(', ')}
            />
          </div>
        
          <div style = {{
            flexDirection: 'row',
            textAlign: 'center',
            marginTop: 20,
          }}>
            <Button
              label='Clear'
              onClick={clearStationInput}
              style = {{
                marginLeft: 5,
                marginRight: 5,
              }}
              disabled={(manualStationIds == null || manualStationIds.length === 0)}
            />
            <Button
              label='OK'
              onClick={changeStationInput}
              style = {{
                marginTop: 10,
                marginLeft: 5,
                marginRight: 5,
              }}
              disabled={(manualStationIds == null || manualStationIds.length === 0)}
            />
          </div>
        </>
      );
    }
  
    return (
      <>
        {(currentDevice == null && (selectedDevice == null || selectedDevice.serialNumber === '')) && (
          <>
            <div className='form-field'>
              <small>
                Type a least 3 characters for matches to be listed below
              </small>
              <input
                id='Device'
                autoComplete='off'
                placeholder='Type to select'
                onChange={handleDeviceSearch}
                className='vehicle-card__deviceSerialNumberDropdown'
              />
            </div>
  
            <div className='results' style={{ position: 'static', height: '13.5rem', border: '1px solid rgb(194, 198, 199)' }}>
              {loading ? (
                <div className='results-loader'>
                  <CircularProgress size='2rem' color='inherit' />
                </div>
              ) : (
                <>
                  {allDevices.map(
                    (device: Device) => (
                      (!devices.find(x => x.id === device.id)) &&
                      <CustomOption
                        key={device.id}
                        className='option'
                        id={device.id.toString()}
                        value={device.serialNumber}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDeviceSelect(device)}
                        >
                        {device.serialNumber}
                      </CustomOption>
                    )
                  )}
                </>
              )}
            </div>
          </>
        )}
  
        {(selectedDevice != null && selectedDevice.serialNumber !== '' && selectedDevice !== currentDevice) && (
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
            <div id="1" style={{ flex: 1, alignItems: 'center', flexDirection: "row", justifyContent: "center", fontSize: 18, minWidth: '200px' }}>
              <p>Selected Device:</p>
              <p>
                <b><u>{selectedDevice?.serialNumber}</u></b>
              </p>
            </div>
        
            <div id="2" style={{ flex: 1, alignItems: 'center', flexDirection: "row", justifyContent: "center", fontSize: 18, minWidth: '200px' }}>
              <p>Station ID:</p>
              <select onChange={handleStationSelect} value={selectedStation || ''}>
                <option value='' disabled>Select a station</option>
                {stationIds.map(stationId => (
                  <option key={stationId} value={stationId}>{stationId}</option>
                ))}
              </select>
            </div>
          </div>
        )}
        
        <div style = {{
          flexDirection: 'row',
          textAlign: 'center',
          marginTop: 20,
        }}>
          <Button
            label='Clear'
            onClick={clearSelectedDevice}
            style = {{
              marginLeft: 5,
              marginRight: 5,
            }}
            disabled={(selectedDevice == null || selectedDevice.serialNumber === '')}
          />
          <Button
            label='OK'
            onClick={changeLinkedDevice}
            style = {{
              marginTop: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
            disabled={
              (currentDevice?.serialNumber?.toLocaleLowerCase()?.trim() === selectedDevice?.serialNumber?.toLocaleLowerCase()?.trim()) ||
              !allDevices.find(x => x.serialNumber?.toLocaleLowerCase() === selectedDevice?.serialNumber?.toLocaleLowerCase()) ||
              !selectedStation
            }
          />
        </div>
      </>
    );
  }

  function getAddDeviceModal() {
    return <Modal
      ref={deviceModalRef}
      onClose={abortDeviceSearch}
      title={stationIds.length === 0 ? 'Add Stations' : 'Add Device'}
      trigger={
        <Button
          style={{ marginRight: 10, }}
          label="Add Device"
        />
      }>
      <div className='form-field-search'>

        {
          renderAddDeviceModalContent()
        }

      </div>
    </Modal>
  }

  function getRemoveDeviceModal() {
    return <Modal
      innerHeight='25rem'
      ref={deviceModalRef}
      onClose={abortDeviceSearch}
      title={currentDevice?.serialNumber ? 'Change Device' : 'Link Device'}
      trigger={
        <Button
          style={{ marginRight: 10, }}
          label="Remove Device"
        />
      }>
      <div className='form-field-search'>
        <div className='form-field'>
          <small>
            Type a least 3 characters for matches to be listed below
          </small>
          <input
            id='Device'
            autoComplete='off'
            placeholder='Type to select'
            onChange={handleDeviceSearch}
            title={selectedDevice?.serialNumber}
            value={selectedDevice?.serialNumber}
            className='vehicle-card__deviceSerialNumberDropdown'
          />
        </div>
        <div className='results' style={{ position: 'static', height: '13.5rem', border: '1px solid rgb(194, 198, 199)' }}>
          {loading ? (
            <div className='results-loader'>
              <CircularProgress size='2rem' color='inherit' />
            </div>
          ) : (
            <>
              {
                devices.map(
                  (device: Device) => (
                    <div
                      key={device.id}
                      className='option'
                      id={device.id.toString()}
                      data-value={device.serialNumber}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeviceSelect(device)}
                      >
                      {device.serialNumber}
                    </div>
                  )
                )
              }
            </>
          )}
        </div>
        <Button
          label='OK'
          fullWidth={true}
          onClick={removeDevice}
          disabled={
            (currentDevice?.serialNumber?.toLocaleLowerCase()?.trim() === selectedDevice?.serialNumber?.toLocaleLowerCase()?.trim()) || // not changed
            !devices.find(x => x.serialNumber?.toLocaleLowerCase() === selectedDevice?.serialNumber?.toLocaleLowerCase()) // not a valid choice
          }
        />
      </div>
    </Modal>
  }

  async function addUnit(serialNumber: string) {
    try {
      if (serialNumber === undefined || serialNumber === "") {
        alert("Please select a device");
        return;
      }

      if (selectedStation === undefined || selectedStation === "") {
        alert("Please select a station");
        return;
      }

      const URL = `${settings.burnInURL}/TestRunner/AddUnit`;
      const response = await axios.put(URL, null, {
        params: {
          unitSerialNumber: serialNumber,
          stationId: selectedStation
        }
      });
  
      // console.log("Device added successfully");
      // console.log(response.data);
      if (response.status === 200) {
        // Remove selected station from station list
        const station = selectedStation;
        setStationIds(stationIds.filter(x => x !== station));
    
        // Clear selected station
        setSelectedStation(undefined);

        alert(response.data?.message ?? "Device added successfully");
      } else {
        alert("Failed to add device");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function removeUnit(serialNumber: string) {
    try {
      const URL = `${settings.burnInURL}/TestRunner/RemoveUnit`;
      const response = await axios.put(
        URL,
        null,
        {
          params: {
            unitSerialNumber: serialNumber,
          }
        }
      );

      if (response.status === 200) {
        alert(response.data?.message ?? "Device removed successfully");
      } else {
        alert("Failed to remove device");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function startAllTests() {
    try {
      const URL = `${settings.burnInURL}/TestRunner/StartAllTests`;
      const response = await axios.put(
        URL,
        null,
        {},
      );
      if (response.status === 200) {
        alert(response.data?.message ?? "All tests started");
        getResults();
      } else {
        alert("Failed to start tests");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function stopAllTests() {
    try {
      const URL = `${settings.burnInURL}/TestRunner/StopAllTests`;
      const response = await axios.put(
        URL,
        null,
        {},
      );
      if (response.status === 200) {
        alert(response.data?.message ?? "All tests stopped");
        getResults();
      } else {
        alert("Failed to stop tests");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  const getResults = useCallback(async () => {
    try {
      const URL = `${settings.burnInURL}/TestRunner/Results`;
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });
  
      if (response.status === 200) {
        processResults(response.data);
      } else {
        alert("Failed to get test results");
      }
  
      getStationIds();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }, [settings.burnInURL, authorisedUser.accessToken, processResults, getStationIds]);

  useEffect(() => {
    let intervalId: number | undefined;
    if (autoRefresh) {
      intervalId = window.setInterval(getResults, 5000);
    }
    return () => window.clearInterval(intervalId);
  }, [autoRefresh, getResults]);

  function processResults(data: any) {
    const devicesWithResults = data.map((device: any) => ({
      id: device.unitSerialNumber,
      serialNumber: device.unitSerialNumber,
      testResults: device.results,
      testStation: device.testStation,
    }));
    
    setDevices(devicesWithResults);

    // Remove all station ids from stationIds array that are already in use by devices
    const stationIdsToIgnore = devicesWithResults.map((device: Device) => device.testStation);
    
    setStationIds(stationIds.filter(stationId => !stationIdsToIgnore.includes(stationId)));

    // Update test results
    let now = new Date().toUTCString();
    setLastUpdated(now);

    const resultsMap = devicesWithResults.reduce((acc: any, device: any) => {
      acc[device.serialNumber] = device.testResults;
      return acc;
    }, {});
    
    setDeviceTestResults(resultsMap);
  }

  async function getStationIds() {
    try {
      const URL = `${settings.burnInURL}/TestRunner/Stations`;
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });
      
      // console.log("Stations retrieved successfully");
      // console.log(response.data);
      
      if (response.status === 200) {
        // Save station ids if not empty
        if (response.data.length > 0) {
          // Filter out all stations that are already in use by devices
          const availableStations = response.data.filter((stationId: string) => !devices.find((device: Device) => device.testStation === stationId));
          setStationIds(availableStations);
        }
      } else {
        alert("Failed to get station list");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function startTestsFor(serialNumber: string) {
    try {
      const URL = `${settings.burnInURL}/TestRunner/StartUnitTest`;
      const response = await axios.put(
        URL,
        null,
        {
          params: {
            unitSerialNumber: serialNumber,
          }
        }
      );
      if (response.status === 200) {
        alert(response.data?.message ?? "All tests started for device");
      } else {
        alert("Failed to start tests for device");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function retryFailedTestsFor(serialNumber: string) {
    try {
        const URL = `${settings.burnInURL}/TestRunner/RetryFailedTests/${serialNumber}`;
        const response = await axios.put(URL, null);

        if (response.status === 200) {
          alert(response.data ?? "Failed tests retried for device");
        } else {
          alert("Failed to retry tests for device");
        }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function retryAUnitTest(serialNumber: string, testType: string) {
    try {
      const URL = `${settings.burnInURL}/TestRunner/RetryUnitTest/${serialNumber}`;
      const response = await axios.put(URL, { testType });

      if (response.status === 200) {
        alert(response.data ?? `Retrying ${testType} test on unit ${serialNumber}`);
      } else {
        alert("Failed to retry test for device");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  async function stopTestsFor(serialNumber: string) {
    try {
      const URL = `${settings.burnInURL}/TestRunner/StopUnitTest`;
      const response = await axios.put(
        URL,
        null,
        {
          params: {
            unitSerialNumber: serialNumber,
          }
        }
      );
      if (response.status === 200) {
        alert(response.data?.message ?? "All tests stopped for device");
      } else {
        alert("Failed to stop tests for device");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error); // TODO: Let user know
      }
    }
  }

  const renderTestSummary = () => {
    const totalTestsRan = devices.reduce((acc, device) => acc + (deviceTestResults[device.serialNumber]?.length || 0), 0);
    const totalTestsInProgress = devices.reduce((acc, device) => acc + (deviceTestResults[device.serialNumber]?.filter((result: any) => result.status === 'InProgress').length || 0), 0);
    const totalTestsPassed = devices.reduce((acc, device) => acc + (deviceTestResults[device.serialNumber]?.filter((result: any) => result.status === 'Passed').length || 0), 0);
    const totalTestsFailed = devices.reduce((acc, device) => acc + (deviceTestResults[device.serialNumber]?.filter((result: any) => result.status === 'Failed').length || 0), 0);
  
    return (
      <div className="test-summary">
        <div className="summary-container">
          <div className="summary-row">
            <div className="summary-item">
              <h4>Total tests:</h4>
              <p>{totalTestsRan}</p>
            </div>
            <div className="summary-item">
              <h4>Total in progress:</h4>
              <p>{totalTestsInProgress}</p>
            </div>
            <div className="summary-item">
              <h4>Total <span className="passed">passed</span>:</h4>
              <p>{totalTestsPassed}</p>
            </div>
            <div className="summary-item">
              <h4>Total <span className="failed">failed</span>:</h4>
              <p>{totalTestsFailed}</p>
            </div>
          </div>
          <div className="controls">
            <div className="control-item">
              <FormControlLabel
                label="Auto Refresh"
                labelPlacement="start"
                control={
                  <Switch
                    checked={autoRefresh}
                    onChange={() => setAutoRefresh(!autoRefresh)}
                    color="primary"
                  />
                }
              />
            </div>
            <div className="last-updated">
              <p>Last Updated: {lastUpdated}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <FilterNavigation
        filters={agentBurnInFilters}
        sortOptions={burnInSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <div className="all-devices">
        <div className="filter-navigation">
          <div className="modal-buttons">
            {getAddDeviceModal()}
            {getRemoveDeviceModal()}
          </div>
    
          <div className="action-buttons">
            <Button
              onClick={startAllTests}
              disabled={devices.length === 0}
              label="Run all tests"
            />
            <span className="button-separator" />
            <Button
              onClick={stopAllTests}
              disabled={devices.length === 0}
              label="Stop all tests"
            />
          </div>
        </div>
    
        {renderTestSummary()}
    
        <DeviceList>
          {loading && (
            <div className='client-list__loader'>
              <CircularProgress />
            </div>
          )}
          {!loading &&
            devices
              .filter((device: Device) => {
                return !searchTextLowerCase ||
                  device.serialNumber.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
              })
              .map((device: Device) => (
                <DeviceCard
                  key={device.id}
                  deviceData={{
                    id: device.id,
                    active: device.active,
                    serialNumber: device.serialNumber,
                    testResults: deviceTestResults[device.serialNumber] || [],
                    lastUpdated: lastUpdated,
                    stationId: device.testStation || 'N/A',
                  }}
                  onRunAllTests={startTestsFor}
                  onRetryFailedTests={retryFailedTestsFor}
                  onStopAllTests={stopTestsFor}
                  onViewTestResults={getResults}
                  onRerunTest={retryAUnitTest}
                />
              ))}
          {!loading && devices.length === 0 && (
            <div className='client-list__loader'>
              <p>No devices added yet</p>
            </div>
          )}
        </DeviceList>
    
        <Fab
          color="primary"
          aria-label="get-results"
          onClick={getResults}
          className="fab"
        >
          {devices.length === 0 ? "Get Devices" : "Get Results"}
        </Fab>
      </div>
    </>
  );
}