import { useEffect, useRef, useCallback } from "react";

type TimerCallback = (state?: any) => void | Promise<void>;

interface StartOptions {
  interval: number,
  callback: TimerCallback,
  immediate?: boolean,
  state?: any
}

export default function useTimer(): [(options: StartOptions) => void, () => void, boolean] {
  
  const isExecuting = useRef<boolean>(false);
  const intervalRef = useRef<number | null>(null); 

  const stop = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      isExecuting.current = false;
    }
  }, []);

  const start = useCallback(async (options: StartOptions) => {
    
    if (intervalRef.current) {
      stop();
    }

    if (options.immediate) {
      setTimeout(async () => {
        try {
          const result = options.callback(options.state);
          if (result && typeof result.then === 'function') {
            await result;
          }
        } catch (error) {
          console.error(error);
        }
      });
    }

    intervalRef.current = window.setInterval(async () => {
      if (!isExecuting.current) {
        try {
          isExecuting.current = true;
          const result = options.callback(options.state);
          if (result && typeof result.then === 'function') {
            await result;
          }
        } catch (error) {
          console.error(error);
        } finally {
          isExecuting.current = false;
        }
      }
    }, options.interval);

  }, [stop]);

  useEffect(() => {
    return () => stop();
  }, [stop]);

  const isActive = !!intervalRef.current;

  return [start, stop, isActive];
}
