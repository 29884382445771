import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';
import axios from 'axios';
import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import FormInputSelect, {
  SelectOptionType,
} from 'components/FormInputSelect/FormInputSelect';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { getYearOptions } from 'functions/getYearOptions';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientDetailType } from './AddClientForm';
import CustomOption from 'components/CustomOption/CustomOption';

interface VehicleFormProps {
  clientDetails: ClientDetailType;
  handleFormChange: (step: string) => void;
}

// This const should go somewhere else. Also,
// default error message should be better.
const DEFAULT_BACKEND_ERROR_MESSAGE = 'Unfortunately the request failed without any good reason.';

export default function AddVehicleForm({
  clientDetails,
  handleFormChange,
}: VehicleFormProps) {
  const navigate = useNavigate();
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const yearOptions = getYearOptions();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleData, setVehicleData] = useState({
    colour: '',
    registrationNumber: '',
    vinNumber: '',
    engineNumber: '',
    vehicle: '',
    year: '',
    userId: 0,
    vehicleTypeId: 0,
  });
  const [vehicleDataErrors, setVehicleDataErrors] = useState({
    colour: [],
    registrationNumber: [],
    vehicle: []
  });
  const [formError, setFormError] = useState('');

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setVehicleData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setFormError('');
  }

  function handleBlur(e: any) {
    const { id, value } = e.target;

    if (value === '') {
      setVehicleDataErrors((prevState) => ({
        ...prevState,
        [id]: ['This field is required'],
      }));
    } else {
      setVehicleDataErrors((prevState) => ({ ...prevState, [id]: [] }));
    }
  }

  async function getVehicles(searchQuery: string) {
    setLoadingSearch(true);
    if (settings?.baseURL) {
      const URL = `${settings.baseURL}/VehicleTypes/Search?SearchPhrase=${searchQuery}&ResultCount=50`;

      try {
        const response = await axios.get(URL, {
          headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
        });

        setVehicleTypes(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
    }
    setLoadingSearch(false);
  }

  function handleVehicleSearch(e: any) {
    setVehicleData((prevState) => ({
      ...prevState,
      vehicle: e.target.value,
    }));

    if (e.target.value.length >= 3) {
      setSearching(true);
      getVehicles(e.target.value);
    } else {
      setSearching(false);
    }
  }

  const { clientId } = useParams();

  function handlePickSearch(e: any) {
    const { id, make, variant } = e;
    console.log("e: ", e);
    console.log("Vehicle details: ", id, variant);

    setVehicleData((prevState) => ({
      ...prevState,
      vehicle: `${make} ${variant}`,
      vehicleTypeId: Number(id),
    }));
    setSearching(false);
  }

  function handleSelect(selectedOption: SelectOptionType) {
    setVehicleData((prevState) => ({
      ...prevState,
      year: selectedOption.label,
    }));
  }

  async function submitDriver(id: number) {
    const updatedClientDetails = {
      ...clientDetails,
      vehicleId: id,
    };

    try {
      const URL = `${settings.baseURL}/Drivers`;
      const response = await axios.post(URL, updatedClientDetails, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      if (response?.status === 200) {
        // Here `id` is the vehicleId that was returned after submitting a vehicle
        navigate(`/agent/onboarding/client/${id}/installation`);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('Errors: ', error.response?.data.errors);
      }
    }
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    const updatedVehicleData = {
      ...vehicleData,
      registrationYear: Number(vehicleData.year),
      vehicleTypeId: vehicleData.vehicleTypeId,
      userId: Number(clientId),
    };

    const URL = `${settings.baseURL}/Vehicles`;
    try {
      const response = await axios.post(URL, updatedVehicleData, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      if (response?.status === 200) {
        const { id } = response.data;

        await submitDriver(id);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.status === 400) {
          setVehicleDataErrors(error.response?.data.errors);
        }
        else {
          setFormError(
            error?.response?.data?.title || error?.response?.data.message || DEFAULT_BACKEND_ERROR_MESSAGE
          );
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p aria-label='vehicle details title'>Vehicle details</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <InfoBox
          title='What vehicle will we be securing?'
          helperText='Obtain the relevant vehicle details, including the make, model, year.
          Please inform the client that they can access this information from their vehicle license disk.
          This information is vital for setting up the appropriate security features and ensuring a seamless
          installation&nbsp;process.'
        />
        <FormRow>
          <div className='form-field-search'>
            <FormInput
              id='vehicle'
              label='Vehicle'
              value={vehicleData.vehicle}
              onChange={handleVehicleSearch}
              helpText='Enter 3 characters to start the search'
              onBlur={handleBlur}
              errorMessage={vehicleDataErrors.vehicle}
            />
            {searching && (
              <div className='results'>
                {loadingSearch ? (
                  <div className='results-loader'>
                    <CircularProgress size='2rem' color='inherit' />
                  </div>
                ) : (
                  <>
                    {vehicleTypes.length === 0 && (
                      <small>Sorry, we don't have that vehicle listed</small>
                    )}
                    {vehicleTypes.map(
                      (vehicle: { id: string; makeVariant: string }) => (
                        <CustomOption
                          key={vehicle.id}
                          value={vehicle.makeVariant}
                          onClick={() => handlePickSearch(vehicle)}
                          id={vehicle.id}
                          ariaLabel={vehicle.makeVariant}
                          className="select__option"
                        >
                          {vehicle.makeVariant}
                        </CustomOption>
                      )
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </FormRow>
        <FormRow>
          <FormInputSelect
            options={yearOptions}
            label='Year'
            value={{
              value: vehicleData.year,
              label: vehicleData.year,
            }}
            handleSelection={handleSelect}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='registrationNumber'
            label='Registration'
            value={vehicleData.registrationNumber}
            onChange={handleInputChange}
            onBlur={handleBlur}
            errorMessage={vehicleDataErrors.registrationNumber}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='colour'
            label='Colour'
            value={vehicleData.colour}
            onChange={handleInputChange}
            onBlur={handleBlur}
            errorMessage={vehicleDataErrors.colour}
          />
        </FormRow>
        {/* <FormRow>
          <FormInput
            id='vinNumber'
            label='VIN number'
            value={vehicleData.vinNumber}
            onChange={handleInputChange}
            optional
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='engineNumber'
            label='Engine number'
            value={vehicleData.engineNumber}
            onChange={handleInputChange}
            optional
          />
        </FormRow> */}
        {formError && (
          <small className='form-field__message form-field__message--error'>
            {formError}
          </small>
        )}
      </FormLayout.Main>
      <FormLayout.Footer alignment='space-between'>
        <div
          aria-label='back button'
          className='back-arrow'
          onClick={() => handleFormChange('driver-details')}
        >
          <ArrowBackIcon />
        </div>
        <Button
          label={isSubmitting ? 'Submitting' : 'Next'}
          onClick={handleSubmit}
          disabled={
            !vehicleData.colour ||
            !vehicleData.vehicle ||
            !vehicleData.year ||
            !vehicleData.registrationNumber ||
            isSubmitting
          }
        />
      </FormLayout.Footer>
    </FormLayout>
  );
}
