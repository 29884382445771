import React from 'react';
import './CustomOption.scss';

interface CustomOptionProps {
  /** Unique key for the option element */
  key: string | number;
  /** Unique id for the option element */
  id: string;
  /** The value of the option */
  value: string;
  /** Optional style for the option element */
  style?: React.CSSProperties;
  /** Click event handler */
  onClick: () => void;
  /** The content of the option */
  children: React.ReactNode;
  /** Optional className for additional styling */
  className?: string;
  /** Optional disabled state */
  disabled?: boolean;
  /** Aria label for accessibility */
  ariaLabel?: string;
}

export const CustomOption = ({
  key,
  id,
  value,
  style,
  onClick,
  children,
  className,
  disabled,
  ariaLabel,
}: CustomOptionProps) => {
  return (
    <div
      key={key}
      id={id}
      data-value={value}
      style={{ cursor: 'pointer', ...style }}
      onClick={onClick}
      className={`custom-option ${className} ${disabled ? 'disabled' : ''}`}
      aria-disabled={disabled}
      aria-label={ariaLabel} // Add aria-label for accessibility
    >
      {children}
    </div>
  );
};

export default CustomOption;