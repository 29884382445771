import './Button.scss';

interface ButtonProps {
  /** Disabled state */
  disabled?: boolean;
  /** Should the button be full width */
  fullWidth?: boolean;
  /** Should be a grey button */
  secondary?: boolean;
  /** Should be a transparent */
  outline?: boolean;
  /** Should be a transparent */
  outlineFilled?: boolean;
  /** Should be a smaller button */
  outlineWhite?: boolean;
  /** Should be a smaller button */
  small?: boolean;
  /** Should be an extra smaller button */
  xsmall?: boolean;
  /** Should have an icon */
  icon?: boolean;
  /** Text should remain on one line */
  nowrap?: boolean;
  /** The text content */
  label?: string;
  /** The icon to render inside the button */
  iconComponent?: React.ReactElement;
  /** Optional id */
  id?: string;
  /** Click handler */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  // Style props
  style?: React.CSSProperties;
}

/** Primary UI component for user interaction */
export default function Button({
  disabled,
  fullWidth,
  secondary,
  outline,
  outlineFilled,
  outlineWhite,
  small,
  xsmall,
  icon,
  nowrap,
  label,
  iconComponent,
  onClick,
  id,
  style,
}: ButtonProps) {
  return (
    <button
      id={id}
      className={`button
          ${fullWidth ? 'button--full-width' : ''}
          ${secondary ? 'button--secondary' : ''}
          ${outline ? 'button--outline' : ''}
          ${outlineFilled ? 'button--filled' : ''}
          ${outlineWhite ? 'button--white' : ''}
          ${small ? 'button--small' : ''}
          ${xsmall ? 'button--xsmall' : ''}
          ${icon ? 'button--icon' : ''}
          ${nowrap ? 'button--nowrap' : ''}
        `}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {iconComponent ? <> {iconComponent} </> : null}
      {label}
    </button>
  );
}
