export const defaultMenuFor = (userRole: string) => {
  // Uncomment when 'Manage agents is activated'
  // if (userRole === 'Admin') {
  //   return [
  //     { path: '/agents/manage', label: 'Manage agents' },
  //     { path: '/password/edit', label: 'Change password' },
  //     { path: '#', label: 'Logout' },
  //   ];
  // } else {
  //   return [
  //     { path: '/password/edit', label: 'Change password' },
  //     { path: '#', label: 'Logout' },
  //   ];
  // }
  if (userRole === 'Admin' || userRole === 'Technical-Agent') {
    return [
      { path: '/agent/clients/all', label: 'Clients' },
      { path: '/agent/onboarding', label: 'Onboarding' },
      { path: '/agent/companies', label: 'Companies' },
      { path: '/agent/onboarding/client/add', label: 'Add a new account' },
      { path: '/admin/add-agent', label: 'Add agent to dashboard' },
      { path: '/admin/add-company', label: 'Add company to dashboard' },
      { path: '/reset-password', label: 'Change password' },
      { path: '#', label: 'Logout' },
    ];
  }
  else if (userRole === 'Company-Admin') {
    return [
      { path: '/agent/clients/all', label: 'Users' },
      { path: '/agent/companies', label: 'Companies' },
      { path: '/reset-password', label: 'Change password' },
      { path: '#', label: 'Logout' },
    ];
  } 
  else {
    return [
      { path: '/agent/clients/all', label: 'Clients' },
      { path: '/agent/onboarding', label: 'Onboarding' },
      { path: '/reset-password', label: 'Change password' },
      { path: '#', label: 'Logout' },
    ];
  };
}

export const clientSortOptions = [
  { path: '', label: 'Latest' },
  { path: '', label: 'Active alerts' },
  { path: '', label: 'A - Z' },
  { path: '', label: 'Z - A' },
];

export const defaultSortOptions = [
  { path: '', label: 'Latest' },
  { path: '', label: 'A - Z' },
  { path: '', label: 'Z - A' },
];

export const burnInSortOptions = [
  { path: '', label: 'Latest' },
];

export const sortMenu = [
  { path: '#', label: 'Latest' },
  { path: '#', label: 'Active alerts' },
  { path: '#', label: 'Locked' },
  { path: '#', label: 'Unlocked' },
  { path: '#', label: 'Low battery' },
  { path: '#', label: 'A - Z' },
  { path: '#', label: 'Z - A' },
];

export const accountMenu = [
  { path: '/agent/onboarding/client/:clientId/add-driver', label: 'Add a vehicle' },
  { path: '/agent/clients/:clientId/account/view', label: 'View account' },
  // { path: '/account/history', label: 'View account history' },
  // { path: '/account/delete', label: 'Delete account' },
];

export const detailsMenu = [
  { path: '/vehicle/edit', label: 'Edit details' },
  { path: '/vehicle/payment', label: 'Payment history' },
  { path: '/vehicle/delete', label: 'Delete request' },
];
