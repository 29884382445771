import { Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import DashboardLayout from 'layouts/DashboardLayout/DashBoardLayout';
import AddAgent from 'pages/Admin/AddAgent';
import VehicleHistory from 'pages/Agent/Clients/Account/VehicleHistory';
import ViewAccount from 'pages/Agent/Clients/Account/ViewAccount';
import Clients from 'pages/Agent/Clients/Clients';
import AllClients from 'pages/Agent/Clients/Filters/AllClients';
import BookingPending from 'pages/Agent/Onboarding/Filters/BookingPending';
import OutstandingDetails from 'pages/Agent/Onboarding/Filters/OutstandingDetails';
import Scheduled from 'pages/Agent/Onboarding/Filters/Scheduled';
import AddClientForm from 'pages/Agent/Onboarding/Forms/AddClientForm';
import AddDriverAndVehicle from 'pages/Agent/Onboarding/Forms/AddDriverAndVehicle';
import InstallationForm from 'pages/Agent/Onboarding/Forms/InstallationForm';
import PaymentForm from 'pages/Agent/Onboarding/Forms/PaymentForm';
import Onboarding from 'pages/Agent/Onboarding/Onboarding';
import CreatePassword from 'pages/CreatePassword/CreatePassword';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import LogIn from 'pages/LogIn/LogIn';
import NotFound from 'pages/NotFound/NotFound';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import SignUpForm from 'pages/SignUpForm/SignUpForm';
import Success from 'pages/Success/Success';
import VerifyAccount from 'pages/VerifyAccount/VerifyAccount';
import InstallationChecklistForm from 'pages/Installation/InstallationChecklistForm';
import Companies from 'pages/Agent/Companies/Companies';
import AllCompanies from 'pages/Agent/Companies/Filters/AllCompanies';
import ViewCompany from 'pages/Agent/Companies/Profile/ViewCompany';
import ViewAndLinkCompanyUsers from 'pages/Agent/Companies/LinkUser/ViewAndLinkCompanyUsers';
import AddCompany from 'pages/Admin/AddCompany';
import Devices from 'pages/Agent/Devices/Devices';
import AllDevices from 'pages/Agent/Devices/Filters/AllDevices';

function App() {
  const CLIENT_FACING_APP = window.location.hostname
    .toLowerCase()
    .startsWith('app.');

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        index
        element={
          // Check the current host and redirect accordingly
          // "app.titansecure.io" -> /sign-up
          // any other host -> /login
          <Navigate to={CLIENT_FACING_APP ? '/sign-up' : '/login'} />
        }
      />
      <Route path='/login' element={<LogIn />} />
      <Route path='/sign-up' element={<SignUpForm />} />
      <Route path='/verify-account' element={<VerifyAccount />} />
      <Route path='/success' element={<Success />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/*' element={<NotFound />} />

      <Route path='/installation-checklist' element={<InstallationChecklistForm />} />
      <Route path='/create-password' element={<CreatePassword />} />

      {/*  Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path='/agent' element={<DashboardLayout />}>
          <Route index element={<Navigate to='clients/all' />} />

          <Route path='clients' element={<Clients />}>
            <Route index element={<Navigate to='all' />} />
            <Route path='all' element={<AllClients />} />
            {/* <Route path='deactivated' element={<DeactivatedClients />} /> */}
          </Route>

          <Route path='onboarding' element={<Onboarding />}>
            <Route index element={<Navigate to='outstanding-details' />} />
            <Route
              path='outstanding-details'
              element={<OutstandingDetails />}
            />
            <Route path='booking-pending' element={<BookingPending />} />
            <Route path='scheduled' element={<Scheduled />} />
          </Route>

          <Route path='companies' element={<Companies />}>
            <Route index element={<Navigate to='all' />} />
            <Route path='all' element={<AllCompanies />} />
          </Route>

          <Route path='burnin' element={<Devices />}>
            <Route index element={<Navigate to='all' />} />
            <Route path='all' element={<AllDevices />} />
          </Route>
        </Route>

        <Route
          path='/agent/clients/:clientId/vehicle/:vehicleId/history'
          element={<VehicleHistory />}
        />
        <Route
          path='/agent/clients/:clientId/account/view'
          element={<ViewAccount />}
        />
        <Route
          path='/agent/onboarding/client/add'
          element={<AddClientForm />}
        />
        <Route
          path='/agent/onboarding/client/:clientId/add-driver'
          element={<AddDriverAndVehicle />}
        />
        <Route
          path='/agent/onboarding/client/:clientId/installation'
          element={<InstallationForm />}
        />
        <Route
          path='/agent/onboarding/client/:clientId/payment'
          element={<PaymentForm />}
        />
        <Route
          path='/agent/companies/:companyId/view'
          element={<ViewCompany />}
        />

        <Route
          path='agent/companies/:companyId/link-user'
          element={<ViewAndLinkCompanyUsers />}
        />

        <Route path='/admin/add-agent' element={<AddAgent />} />

        <Route path='/admin/add-company' element={<AddCompany />} />
      </Route>
    </Routes>
  );
}

export default App;
