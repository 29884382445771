import './VehicleCard.scss';
import { DriverInterface } from 'types/DriverInterface';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/locked.svg';
import { ReactComponent as UnlockIcon } from 'assets/icons/unlocked.svg';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSignalR } from 'context/useSignalR';
import axios, { AxiosError } from 'axios';
import Button from 'components/Button/Button';
import InfoCard from 'components/InfoCard/InfoCard';
import Mapview from 'components/Mapview/Mapview';
import Modal from 'components/Modal/Modal';
import { Device } from 'types/Device';
import { GetDeviceEventsStatusInfoResponse, } from 'types/DeviceEventStatusInfo';
import { CircularProgress } from '@mui/material';
import CustomOption from 'components/CustomOption/CustomOption';

export interface VehicleProps {
  /** Device status */
  deviceActive: boolean;
  /** Vehicle Drivers */
  drivers: DriverInterface[];
  /** Vehicle locked status */
  isLocked: boolean;
  /** Vehicle current GPS location */
  gps: { lat: number | null; lng: number | null };
  /** Vehicle make */
  make: string;
  /** Vehicle nickname */
  nickname: string;
  /** Vehicle registration number */
  registration: string;
  /** Last pps update date */
  gpsDateTime: string;
  /** Vehicle tamper status */
  tamperActive: boolean;
  /** Vehicle ID */
  vehicleId: number;
  /** Device ID */
  deviceId: number;
  /** Device Serial Number */
  deviceSerialNumber: string;
}

/** A UI component to render the details of a user's vehicle */
export default function VehicleCard({
  make,
  registration,
  deviceActive,
  gps,
  gpsDateTime,
  isLocked,
  nickname,
  tamperActive,
  drivers,
  vehicleId,
  deviceId,
  deviceSerialNumber
}: VehicleProps) {
  const settings = useAppSettings();
  const deviceModalRef = useRef();
  const { authorisedUser } = useAuth();
  const { signalGPS, signalLock } = useSignalR();
  const [deviceEventStatusInfo, setDeviceEventStatusInfo] = useState<GetDeviceEventsStatusInfoResponse>();
  const [sendingDeviceCommand, setSendingDeviceCommand] = useState(false);
  const [sendingAlertCommand, setSendingAlertCommand] = useState({
    AlertAura: false,
    AlertCapitalAir: false,
  });
  const [commandNotification, setCommandNotification] = useState({
    status: '',
    message: '',
  });

  const initialGPSLocation = {
    lat: gps.lat,
    lng: gps.lng,
  };

  const [GPSLocation, setGPSLocation] = useState(initialGPSLocation);
  const [lockedState, setLockedState] = useState(isLocked);
  const date = new Date(gpsDateTime).toLocaleDateString('en-ZA', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  const time = new Date(gpsDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const [coordsCopied, setCoordsCopied] = useState(false);

  const [devices, setDevices] = useState<Device[]>([]);
  const [loadingDeviceSearch, setLoadingDeviceSearch] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Device>({
    id: deviceId,
    active: deviceActive,
    serialNumber: deviceSerialNumber
  });
  const [currentDevice, setCurrentDevice] = useState<Device>({
    id: deviceId,
    active: deviceActive,
    serialNumber: deviceSerialNumber
  });

  useEffect(() => {
    // Set streaming GPS
    if (signalGPS.vehicleId === vehicleId) {
      setGPSLocation({
        lat: signalGPS?.latitudeDegrees,
        lng: signalGPS?.longitudeDegrees,
      });
    }

    // Set streaming Lock
    if (signalLock.vehicleId === vehicleId) {
      setLockedState(signalLock?.locked);
    }
  }, [signalLock, signalGPS, vehicleId]);

  useEffect(() => {
    getLastDeviceEventStatusInfo(deviceId);
  }, [currentDevice]);

  const handleLockUnlockClick = async () => {
    if (lockedState === null) return;

    const URL = `${settings.baseURL}/Vehicles/${vehicleId}/${lockedState ? 'Unlock' : 'Lock'
      }`;

    setSendingDeviceCommand(true);

    try {
      const response = await axios.put(
        URL,
        {},
        { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } }
      );

      if (response?.status === 200) {
        setSendingDeviceCommand(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.message);
      }
    } finally {
      setSendingDeviceCommand(false);
    }
  };

  function clearAlertStatus() {
    setTimeout(() => setCommandNotification({ status: '', message: '' }), 2000);
  }

  async function handleAlertCommand(event: React.MouseEvent<HTMLElement>) {
    const target = event.target as HTMLInputElement;
    setCommandNotification({ status: '', message: '' });

    const URL = `${settings.baseURL}/Vehicles/${vehicleId}/${target.id}`;
    setSendingAlertCommand((prevState) => ({
      ...prevState,
      [target.id]: true,
    }));

    try {
      const response = await axios.post(
        URL,
        {},
        { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } }
      );

      if (response?.status === 200) {
        setCommandNotification({
          status: 'success',
          message: 'Alert sent',
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setCommandNotification({
          status: 'error',
          message: error.response?.data.message,
        });
      }
    } finally {
      setSendingAlertCommand((prevState) => ({
        ...prevState,
        [target.id]: false,
      }));
      clearAlertStatus();
    }
  }

  function copyToClipboard() {
    setCoordsCopied(true);
    navigator.clipboard.writeText(
      `Lat: ${GPSLocation.lat}, Lng: ${GPSLocation.lng}`
    );
    setTimeout(() => {
      setCoordsCopied(false);
    }, 1000);
  }

  async function getLastDeviceEventStatusInfo(deviceId: number) {
    try {
      const response = await axios.post(
        `${settings.baseURL}/My/Device/GetDeviceStatusInfo`,
        { deviceId },
        { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } }
      );
  
      if (response?.status !== 200) { //TODO:What about non-200?
        throw new AxiosError(response?.data?.message ?? response?.statusText);
      }
  
      // Process response data
      setDeviceEventStatusInfo(response.data);
  
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error?.response?.data?.message ?? error?.response?.statusText ?? error?.message ?? 'Failed to get device status info'); // TODO:Until we sort out notifications
      }
    }
  }

  const changeLinkedDevice = async () => {
    const URL = `${settings.baseURL}/Vehicles/${vehicleId}/ChangeLinkedDevice`;//TODO:API endpoint Not RESTy
    try {
      const response = await axios.post(URL,
        {
          currentDeviceSerialNumber: currentDevice.serialNumber,
          newDeviceSerialNumber: selectedDevice.serialNumber,
          vehicleNickname: nickname
        }
        , { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } });

      if (response?.status !== 200) {
        throw new AxiosError(response?.data?.message ?? response?.statusText);
      }

      // reconcile current and selected

      setSelectedDevice(response.data);
      setCurrentDevice(response.data);

      // close search modal

      (deviceModalRef.current as any)?.close();

      // clear devices

      setDevices([]);

    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        alert(error?.response?.data?.message ?? error?.response?.statusText ?? error?.message ?? 'Failed to change device'); // TODO:Until we sort out notifications
      }
    }
  }

  async function getDevices(searchText: string) {
    try {

      // show progress

      setLoadingDeviceSearch(true);

      // fetch

      const URL = `${settings.baseURL}/Devices?$filter=VehicleId eq 0 and contains(SerialNumber, '${searchText}')&$pagesize=50&$orderby=SerialNumber asc`;
  
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      // Add error handling
      if (response?.status === 200) {
        setDevices(response.data.items);
      }
    } catch (error) {

      if (axios.isAxiosError(error)) {
        console.log(error); //TODO: User has to know
      }

    } finally {

      // hide progress

      setLoadingDeviceSearch(false);
    }
  }

  function handleDeviceSearch(event: ChangeEvent<HTMLInputElement>) {

    var { value } = event.target;

    // remember input

    setSelectedDevice(prevState => ({
      ...prevState,
      id: 0,
      active: false,
      serialNumber: value,
    }));

    if (value.length >= 3) {
      getDevices(value);
    } else {
      setDevices([]); // clear
    }
  }

  function handleDeviceSelect(device: Device) {
    
    // remember input

    setSelectedDevice(prevState => ({
      ...prevState,
      ...device
    }));
  }

  function abortDeviceSearch() {
    
    // revert back to current

    setSelectedDevice(prevState => ({
      ...prevState,
      ...currentDevice
    }));

    // clear devices

    setDevices([]);
  }

  return (
    <div className='vehicle-card' aria-label='vehicle card'>
      <div className='vehicle-card__content'>
        <div
          className='vehicle-card__nickname'
          aria-label='vehicle card nickname'
        >
          {nickname ?? make}
        </div>
        <div className='vehicle-card_' aria-label='vehicle card registration'>
          {registration}
        </div>
        <br/>
        <div className="vehicle-card__infoContainer">
          <div className='vehicle-card__infoLabel'>
            Device serial number
          </div>
          <div className='vehicle-card__infoValue' title={selectedDevice.serialNumber}>
            <Modal
              innerHeight='25rem'
              ref={deviceModalRef}
              onClose={abortDeviceSearch}
              title={currentDevice.serialNumber ? 'Change Device' : 'Link Device'}
              trigger={
                <small className='editable' style={{ display: 'block', width:'100%' }}>
                  {currentDevice.serialNumber}
                </small>
              }>
              <div className='form-field-search'>
                <div className='form-field'>
                  <small>
                    Type a least 3 characters for matches to be listed below
                  </small>
                  <input
                    id='Device'
                    autoComplete='off'
                    placeholder='Type to select'
                    onChange={handleDeviceSearch}
                    title={selectedDevice.serialNumber}
                    value={selectedDevice.serialNumber}
                    className='vehicle-card__deviceSerialNumberDropdown'
                  />
                </div>
                <div className='results' style={{ position:'static', height: '13.5rem', border: '1px solid rgb(194, 198, 199)' }}>
                  {loadingDeviceSearch ? (
                    <div className='results-loader'>
                      <CircularProgress size='2rem' color='inherit' />
                    </div>
                  ) : (
                    <>
                      {
                        devices.map(
                          (device: Device) =>
                            <CustomOption
                              key={device.id}
                              className='option'
                              id={device.id.toString()}
                              value={device.serialNumber}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDeviceSelect(device)}
                              >
                              {device.serialNumber}
                            </CustomOption>
                        )
                      }
                    </>
                  )}
                </div>
                <Button
                  label='OK'
                  fullWidth={true}
                  onClick={changeLinkedDevice}
                  disabled={
                    (currentDevice?.serialNumber?.toLocaleLowerCase()?.trim() === selectedDevice?.serialNumber?.toLocaleLowerCase()?.trim()) || // not changed
                    !devices.find(x => x.serialNumber?.toLocaleLowerCase() === selectedDevice.serialNumber?.toLocaleLowerCase()) // not a valid choice
                  }/>
              </div>
            </Modal>
          </div>
        </div>
        <div className="vehicle-card__infoContainer">
          <div className='vehicle-card__infoLabel'>
            Last gps update
          </div>
          <div className='vehicle-card__infoValue'>
            <small>{date}</small> - <small>{time}</small>
          </div>
        </div>
        <div className="vehicle-card__infoContainer">
          <div className='vehicle-card__infoLabel'>
            Application version
          </div>
          <div className='vehicle-card__infoValue'>
            <small style = {{ color: deviceEventStatusInfo?.applicationVersion.includes("No") ? "orange" : "black" }}>
              {deviceEventStatusInfo?.applicationVersion} &nbsp;
            </small>
          </div>
        </div>
        <div className="vehicle-card__infoContainer">
          <div className='vehicle-card__infoLabel'>
            Auto lock state
          </div>
          <div className='vehicle-card__infoValue'>
            <small style={deviceEventStatusInfo?.autoLockEnabledState === 0 ? {color:"orange"} : {color:"green"}}>
              {deviceEventStatusInfo?.autoLockEnabledState === 0 ? "Disabled" : "Enabled"}
            </small>
          </div>
        </div>
      </div>

      {deviceActive && (
        <div className='vehicle-card__map'>
          {GPSLocation.lat === null && GPSLocation.lng === null && (
            <div
              className='info-box map-view'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              GPS coordinates unavailable
            </div>
          )}
          {GPSLocation.lat !== null && GPSLocation.lng !== null && (
            <Mapview
              vehicle={{
                lat: GPSLocation.lat,
                lng: GPSLocation.lng,
              }}
              tamperAlert={tamperActive}
              isLocked={lockedState}
              commandNotification={commandNotification}
              commandButtons={
                <>
                  <Button
                    label={
                      sendingAlertCommand.AlertAura
                        ? 'Sending alert'
                        : 'Alert Aura'
                    }
                    outlineWhite
                    small
                    id='AlertAura'
                    onClick={handleAlertCommand}
                    disabled={sendingAlertCommand.AlertAura}
                  />
                  <Button
                    id='AlertCapitalAir'
                    label={
                      sendingAlertCommand.AlertCapitalAir
                        ? 'Sending alert'
                        : 'Capital Air'
                    }
                    outlineWhite
                    small
                    onClick={handleAlertCommand}
                    disabled={sendingAlertCommand.AlertCapitalAir}
                  />
                </>
              }
            />
          )}
        </div>
      )}
      {GPSLocation.lat !== null && GPSLocation.lng !== null && (
        <div className='coords'>
          <div className='coords__group'>
            <small>Lat: {GPSLocation.lat}</small>
            <small>Lng: {GPSLocation.lng}</small>
          </div>
          <button
            aria-label='copy coordinates'
            className='coords__button'
            onClick={() => copyToClipboard()}
            disabled={coordsCopied}
          >
            {coordsCopied ? 'Copied to clipboard' : 'Copy coordinates'}
            <CopyIcon />
          </button>
        </div>
      )}

      {deviceActive && (
        <div className='vehicle-card__actions'>
          <Modal
            title='Call Driver'
            styleClass='width-full'
            trigger={<Button label='Call driver' fullWidth outline />}
          >
            {drivers[0] && (
              <InfoCard
                title={`${drivers[0].firstName} ${drivers[0].lastName}`}
                driverDetails={drivers[0]}
              />
            )}
          </Modal>

          {sendingDeviceCommand ? (
            <Button label='Submitting' fullWidth disabled={true} />
          ) : (
            <Button
              label={lockedState ? 'Unlock' : 'Lock'}
              fullWidth
              outline={!!lockedState}
              icon
              iconComponent={lockedState ? <UnlockIcon /> : <LockIcon />}
              onClick={handleLockUnlockClick}
            />
          )}
        </div>
      )}
      {!deviceActive && (
        <div className='vehicle-card__actions'>
          <Button label='Reactivate' fullWidth outline />
        </div>
      )}

    </div>
  );
}
